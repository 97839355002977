import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import styled from "styled-components";
import {BlueJeans, DarkGray, Grass1, LightGray, MediumGray, MediumGray1} from "../app/StyleCommon";
import {metrics} from "../utils/metadata";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate() {
    this.renderChart();
  }

  render() {
    return (
      <ChartWrapper className="chart" style={this.props.style}>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data, metric} = this.props;
    if(!this.containerRef.current || !data || !data.length) {
      return;
    }
    data = _.cloneDeep(data);
    data.forEach(d => {
      d.name = d.name || d.key;
      d.value = d[metric];
    })
    data = data.filter(d => !!d.value);

    const metricMeta = metrics.find(d => d.key === metric);
    let formatter = metricMeta.formatter;
    // data = _.sortBy(data, _.property('value')).reverse().slice(0, 5).reverse();
    data = _.sortBy(data, _.property('value'));
    const container = this.containerRef.current;
    const myChart = echarts.init(container, null, {renderer: 'canvas', height: 220});
    let option = {
      textStyle: {fontFamily: '"FKGroteskNeue", "Open Sans", sans-serif'},
      // color: ['#abcdf4'],
      color: ['#6eb3c4'],
      tooltip: {
        trigger: 'axis',
        position: 'top',
        confine: true,
        valueFormatter: metricMeta.formatter
      },
      grid: {
        top: '20',
        left: data.length > 12 ? '55' : '35',
        right: '40',
        bottom: 15,
        containLabel: true
      },
      xAxis: {
        show: false,
        type: 'value',
        boundaryGap: [0, 0.01],
        splitNumber: 2,
        offset: 2,
        axisLabel: {
          formatter: metricMeta.formatter2 || formatter
        },
      },
      yAxis: {
        type: 'category',
        data: data.map(d => d.label || d.key),
        axisLine: {
          onZero: false,
          lineStyle: {
            color: MediumGray,
            // color: BlueJeans,
            fontWeight: 'bold'
          }
        },
        axisTick: {
          alignWithLabel: true
        },
        axisLabel: {
          color: DarkGray,
          overflow: 'ellipsis',
          fontSize: 12,
        },
      },
      series: [
        {
          type: 'bar',
          data: data.map(d => d.value),
          barWidth: 18,
          barGap: 18,
          label: {
            show: true,
            position: 'right',
            formatter: d => formatter(d.value),
          },
          itemStyle: {
            borderRadius: 0,
            // color: '#6eb3c4',
            color: Grass1,
          },
        }
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
`;
