import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import * as d3 from 'd3';
import {withConsumer} from '../app/ApplicationContext';
import {post} from '../utils/request';
import {ProgressBar, DateSelectorDropdown, CondirmDialog, Btn, Table, GridCont} from '../uikit/index';
import {TableScrollWrapper} from '../uikit/index';
import {GridContainer} from '../uikit/grid/index.style';
import {SelectStyled} from './select.style';
import MainLayout from '../layout/index';
import PreviewBox from './previewBox';
import Deployment from './step.deploy';
import CollectionEditor from './slotCollection';
import ErrorLogPanel from './errorLogPanel';
import ClickLogPanel from './clickLogPanel';
import {layouts, itemStyles, algorithms, datePeriodWindows} from '../utils/metadata';
import {StyledDropdown} from '../uikit/dropdown/styled';
import Timeseries from './timeseries';
import {
  WidgetDetailsWrapper,
  WidgetOverview,
  MetricTableWrapper,
  ReportChartWrapper,
  KeymetricCard
} from './details.style';
import {Grass1} from "../app/StyleCommon";
import {metrics} from "./common";
import {filterTags, intFormatter, pct2Formatter, pctFormatter} from "../utils/formatter";
import Dashboardimeseries from "../dashboard/timeseries";
import dashboardMetadata from '../dashboard/metadata';
import Sparkline from '../dashboard/sparkline';
import {WidgetStats, Card} from "../dashboard/index.style";
import {KeyMetricsComparison, SelectInlineStyled, BarWrapper, PaginationStyled} from "../dashboard/index.style";
import {dashboardCards, timeseriesDimensions} from './index.metadata';
import {Transition} from '../uikit/index';
import HistogramTimeseries from "../dashboard/histogram";
import {dashboardCardsMewatch} from "../abtesting/index.metadata";
import MeWatchHistogramTimeseries from "../dashboard/histogram.mewatch";
import InsightExplorer from "../insights";

export default withRouter(withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const {readOnly, appState: {session = {}} = {}} = props;
    const isAdmin = (session || {}).isAdmin;
    this.id = this.props.computedMatch.params.id;
    this.state = {
      period: 'last30days',
      timeseriesDimension: isAdmin ? 'algorithm' : 'slot_position',
      keymetricComparisonDimension: isAdmin ? 'algorithm': 'slot_position',
      kmcPage: 1,
      kmcSortBy: 'ctr',
      kmcSortDirection: 'desc',
    };
  }

  buildInsightQuery() {
    const widgetId = this.props.computedMatch.params.id;
    return {...(_.omit(this.state, ['error', 'loading', 'data'])), id: widgetId};
  }

  render() {
    const {readOnly, appState: {session = {}} = {}} = this.props;
    const {isAdmin, isOperator} = (session || {});
    const {data, error, loading, period} = this.state;
    const {
      timeseriesDimension,
      keymetricComparisonDimension, kmcPage, kmcSortBy, kmcSortDirection
    } = this.state;
    const widgetId = this.props.computedMatch.params.id;
    let {
      widget,
      keyMetrics = {},
      keyMetricsComparison = {},
      histogramBreakDown = {},
      mewatchHistogramReport,
      isMewatchTAIntegration,
    } = data || {};
    const {name, description, site, slotGroups = [], cxenseWidget, thinkWidget, layoutConfig, slotCount} = widget || {};
    const {layout, layoutParams, itemStyle} = layoutConfig || {};
    const {row, column} = layoutParams || {};
    const layoutOption = layouts.find(l => l.key === layout) || {};
    const itemStyleOption = itemStyles.find(o => o.key === itemStyle);

    // let keyMetricsComparisonSorted = (keyMetricsComparison.items || []).filter(d => !!d[keymetricComparisonDimension]);
    let keyMetricsComparisonSorted = (keyMetricsComparison.items || []);
    keyMetricsComparisonSorted = keyMetricsComparisonSorted.slice((kmcPage - 1) * 20, kmcPage * 20);
    keyMetricsComparisonSorted = _.sortBy(keyMetricsComparisonSorted, _.property(kmcSortBy));
    if (kmcSortDirection === 'desc') {
      keyMetricsComparisonSorted = keyMetricsComparisonSorted.reverse();
    }
    if(histogramBreakDown.dimension  === 'algorithm') {
      histogramBreakDown = _.cloneDeep(histogramBreakDown);
      let ifactor = slotCount;
      histogramBreakDown.items.forEach(d => {
        d.impressions = d.impressions / slotCount;
        d.ctr = d.ctr * slotCount;
      })
    }
    const filteredDimensions = timeseriesDimensions.filter(d => {
      if(!isAdmin && d.key === 'algorithm') {
        return false;
      }
      else if(widget && (widget.cxenseWidget || widget.thinkWidget)) {
        return ['slot_position', 'access_method'].indexOf(d.key) >= 0;
      } else {
        return true;
      }
    })

    let dashboardCardsFiltered = dashboardCards;
    if(isMewatchTAIntegration) {
      dashboardCardsFiltered = dashboardCardsMewatch;
    }
    return (
      <MainLayout activeItem="widget" breadcrumb={[
        {path: '/widget', label: 'Widget'},
        {path: `/widget/${this.id}`, label: 'Widget Details'},
      ]}>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryWidgetDetails`}
            params={this.buildInsightQuery()}
            successHandler={data => {
              this.setState({data: data});
              const {keyMetricsComparison = {}, histogramBreakDown = {}} = data;
              this.setState({
                timeseriesDimension: keyMetricsComparison.dimension || timeseriesDimension,
                keymetricComparisonDimension: histogramBreakDown.dimension || keymetricComparisonDimension
              });
            }}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!data &&
          <WidgetDetailsWrapper>
            <div className="detail-header">
              <div className="title">
                <img className="site" src={`/img/property/${widget.site || 'unknown'}.png`}/>
                <span>{widget.name || 'Untitled'}</span>
                <Btn size="small" type="link" disabled={!isAdmin && !isOperator}>
                  <Link to={`/widget/edit/${widget.id}`}><i className="fa fa-edit"/> Design</Link>
                </Btn>
              </div>
              <div className="actions">
                <DateSelectorDropdown
                  mode={'range'}
                  placeholder={'placeholder'}
                  period={period}
                  shortcuts={datePeriodWindows}
                  onDateRangeUpdated={period => this.setState({period, data: null})}/>

                <StyledDropdown
                  toggler={<i className="fa fa-ellipsis-v"/>}>
                  <div className="menu-item">
                    <a onClick={e => this.duplicateWidget(widget)}><i className="fa fa-copy"/>Duplicate</a>
                  </div>
                  <div className="menu-item">
                    <Link to={`/widget/edit/${widget.id}`}><i className="fa fa-edit"/>Edit</Link>
                  </div>
                  <div className="menu-item">
                    <a onClick={e => this.deleteWidget(widget)}><i className="fa fa-trash"/>Delete</a>
                  </div>
                  <div className="menu-item">
                    <Link to={`/widget/history/${widget.id}`}><i className="fa fa-clock-o"/>Revision History</Link>
                  </div>
                </StyledDropdown>
              </div>
            </div>
            <WidgetOverview>
              <div className="attr">
                <label>Tags</label>
                <div className="tag-list">
                  {filterTags(widget.tags || [], 'widget', isAdmin).map(t => {
                    return <span className="tag" key={t}>{t.split('::').pop()}</span>
                  })}
                  {(!widget.tags || !widget.tags.length) && '--'}
                </div>
              </div>
              <div className="attr">
                <label>Layout</label>
                <div className="layout">
                  {/*<div className="layout-icon"*/}
                  {/*style={{backgroundImage: `url(${widget.thumbnail || layoutOption.icon || '/img/icons/widget.png'})`}}>*/}
                  {/*</div>*/}
                  <img src={layoutOption.icon}/>
                  <a onClick={e => this.showPreview(widget)}>
                    <span className="layout-desc">
                    {layoutOption.label || ' -- '} {layoutOption.key ? `(${row}x${column})` : ''}
                      , Item style: {itemStyleOption.label}
                  </span>
                  </a>
                </div>
              </div>
              {
                !!widget.abtestings && !!widget.abtestings.length &&
                <div className="attr">
                  <label>A/B Test</label>
                  <div>
                    {widget.abtestings.map(t => {
                      return (
                        <Link key={t.id} to={`/abtesting/${t.id}`} style={{marginRight: '10px'}}>
                          <i className="fa fa-thermometer-full"/>&nbsp; {t.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              }
              {/*<div className="attr">*/}
              {/*  <label>Deployment Code</label>*/}
              {/*  <div>*/}
              {/*    <a onClick={e => this.showDeployment(widget)}><i className="fa fa-code"/> &nbsp;Show code</a>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="attr">
                <label>Creator</label>
                <div>
                  {
                    !!widget.CreatedDateTime &&
                    <span>{widget.creator} at {moment(widget.CreatedDateTime).format('DD-MMM-YYYY hh:mm A')}</span>
                  }
                  {
                    !!widget.UpdatedDateTime && widget.UpdatedDateTime!== widget.CreatedDateTime &&
                    <span>, Last modified at {moment(widget.UpdatedDateTime).format('DD-MMM-YYYY hh:mm A')}</span>
                  }
                  <Link to={`/widget/history/${widget.id}`}>
                    &nbsp;&nbsp;&nbsp;<i className="fa fa-clock-o"></i>&nbsp; Show all revisions <i
                    className="fa fa-angle-double-right"/>
                  </Link>
                </div>
              </div>
              <div className="attr metric-table">
              <label style={{alignSelf: 'flex-start'}}>Key Metrics</label>
              <KeyMetricsComparison className="key-metric-wrapper">
                <GridContainer type={'flex'} flex={true}>
                  {!!data && dashboardCardsFiltered.map(({formatter, key, label, color, icon}) => {
                    let keyMetricData = keyMetrics[key];
                    let value = keyMetricData;
                    let trending = null;
                    if (keyMetricData && typeof keyMetricData === 'object') {
                      value = keyMetricData.value;
                      trending = keyMetricData.data;
                    }
                    if(!session.isAdmin && key === 'error_rateStat') {
                      return null;
                    } else if(session.isAdmin && key === 'clickStat') {
                      return null;
                    }
                    return (
                      <KeymetricCard
                        className={`card ${key}`}
                        key={key + value}
                        type={color}
                        onClick={e => {
                          // this.showDeeperInsights(key);
                        }}>
                        <div className="top">
                          <i className={icon} aria-hidden="true"></i>
                          <span>{label}</span>
                        </div>
                        <div className="bottom">
                          <h3><Transition>{formatter(value)}</Transition></h3>
                          <div className="rate">
                            <Sparkline data={trending} size="small" formatter={formatter}/>
                          </div>
                        </div>
                      </KeymetricCard>
                    )
                  })}
                </GridContainer>

                {
                  !!session.isAdmin &&
                  <>
                    <TableScrollWrapper>
                      <Table
                        columns={[
                          {
                            key: 'key',
                            // label: dashboardMetadata.getDimensionKeyLabel(keymetricComparisonDimension),
                            label: (
                              isMewatchTAIntegration ? 'Algorithm': <SelectInlineStyled
                                useFixed={true}
                                selected={keyMetricsComparison.dimension || keymetricComparisonDimension}
                                data={filteredDimensions}
                                onChange={dimension => {
                                  this.setState({keymetricComparisonDimension: dimension}, () => this.refreshChart('keyMetricsComparison'))
                                }}/>
                            ),
                            align: 'center',
                            renderer: d => {
                              let dimension = keyMetricsComparison.dimension || keymetricComparisonDimension;
                              return dashboardMetadata.getDimensionLabel(dimension, d[dimension]) || '--';
                            }
                          },
                          {
                            key: 'impressions',
                            label: 'Impressions',
                            align: 'center',
                            sortable: true,
                            renderer: d => {
                              let ifactor = keymetricComparisonDimension === 'algorithm' ? slotCount : 1;
                              keyMetricsComparison.maxImpressions = keyMetricsComparison.maxImpressions || _.max(keyMetricsComparisonSorted.map(x => (x.impressions /ifactor)));
                              return (
                                <BarWrapper>
                                  <div className="num">{intFormatter(Math.round(d.impressions / ifactor))}</div>
                                  <div className="bar"
                                       style={{width: pctFormatter(d.impressions / ifactor / keyMetricsComparison.maxImpressions)}}></div>
                                </BarWrapper>
                              )
                            }
                          },
                          {
                            key: 'clicks',
                            label: 'Clicks',
                            sortable: true,
                            align: 'center',
                            renderer: d => {
                              keyMetricsComparison.maxClicks = keyMetricsComparison.maxClicks || _.max(keyMetricsComparisonSorted.map(x => x.clicks || 0));
                              return (
                                <BarWrapper>
                                  <div className="num">{intFormatter(d.clicks)}</div>
                                  <div className="bar" style={{width: pctFormatter(d.clicks / keyMetricsComparison.maxClicks)}}></div>
                                </BarWrapper>
                              )
                            }

                          },
                          {
                            key: 'ctr',
                            label: 'CTR',
                            sortable: true,
                            align: 'center',
                            renderer: d => {
                              let ifactor = keymetricComparisonDimension === 'algorithm' ? slotCount : 1;
                              keyMetricsComparison.maxCtr = keyMetricsComparison.maxCtr || _.max(keyMetricsComparisonSorted.map(x => x.ctr || 0));
                              return (
                                <BarWrapper>
                                  <div className="num">{pct2Formatter(d.ctr * ifactor)}</div>
                                  <div className="bar"
                                       style={{width: pct2Formatter(d.ctr / keyMetricsComparison.maxCtr)}}></div>
                                </BarWrapper>
                              )
                            }
                          },
                        ]}
                        rows={keyMetricsComparisonSorted}
                        sort={{sortColumn: kmcSortBy, direction: kmcSortDirection}}
                        sortChange={orderBy => {
                          this.setState({'kmcSortBy': orderBy}, () => this.refreshChart('keyMetricsComparison'));
                        }}
                        directionChange={direction => {
                          this.setState({'kmcSortDirection': direction}, () => this.refreshChart('keyMetricsComparison'));
                        }}
                        noDataText={"No Data"}
                      />
                    </TableScrollWrapper>
                    <PaginationStyled
                      onChange={p => this.setState({kmcPage: p})}
                      current={kmcPage}
                      pageSize={20}
                      total={keyMetricsComparison.items.length}
                      showLessItems={true}
                      hideOnSinglePage={true}/>
                  </>
                }
              </KeyMetricsComparison>
            </div>


              {
                !mewatchHistogramReport &&
                <div className="attr timeseries">
                  <label style={{alignSelf: 'flex-start'}}>Analysis by</label>
                  {/*<Timeseries data={histogram.items} period={histogram.period}/>*/}
                  <div>
                    <div className="timeseries-header">
                      <span></span>
                      <SelectInlineStyled
                        selected={timeseriesDimension}
                        data={filteredDimensions}
                        onChange={dimension => {
                          this.setState({timeseriesDimension: dimension}, () => this.refreshChart('histogramBreakDown'))
                        }}/></div>
                    <HistogramTimeseries
                      key={histogramBreakDown.dimension + histogramBreakDown.timestamp}
                      dimension={histogramBreakDown.dimension}
                      showPerformanceBreakdown={true}
                      data={histogramBreakDown}/>
                  </div>
                </div>
              }
              {
                !!mewatchHistogramReport &&
                <MeWatchHistogramTimeseries
                  showPerformanceBreakdown={true}
                  key={mewatchHistogramReport.dimension + mewatchHistogramReport.timestamp}
                  dimension={mewatchHistogramReport.dimension}
                  availableMetrics={[
                    'impressions', 'clicks', 'video_starts','video_completions',
                    'watch_time', 'uvv',
                    'ctr', 'vpr', 'vcr'
                  ]}
                  hideBenchmark={true}
                  data={mewatchHistogramReport}/>
              }
            </WidgetOverview>
          </WidgetDetailsWrapper>
        }
      </MainLayout>
    )
  }

  refreshChart(insightType) {
    const query = this.buildInsightQuery();
    query.requiredInsights = [insightType];
    this.setState({loading: true});
    post('/api/queryWidgetDetails', query).then(newData => {
      let {data: oldData} = this.state;
      this.setState({data: {...oldData, ...newData}, loading: false});
    }).catch(e => {
      this.setState({loading: false});
    });
  }

  showPreview(d) {
    const {appState: {session}} = this.props;
    const siteOption = session.sites.find(s => s.key === d.site);

    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      width: '650px',
      hideCancel: true,
      hideOK: true,
      dialogBody: (
        <div style={{maxHeight: '80vh', overflow: 'auto', marginBottom: '20px'}}>
          <PreviewBox id={d.id} site={d.site} siteOption={siteOption}/>
        </div>
      ),
      onConfirm: () => {
        CondirmDialog.closeAll();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  onDateRangeUpdated(period) {

  }

  showDeployment(widget) {
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: 'Deployment Code',
      width: '800px',
      dialogBody: (
        <div style={{maxHeight: '80vh', overflow: 'auto', marginBottom: '20px'}}>
          <Deployment widget={widget} onChange={widget => null}/>
        </div>
      ),
      onConfirm: () => {
        CondirmDialog.closeAll();
      },
      onCancel: () => {
        CondirmDialog.closeAll();
      }
    }

    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  showErrorLogs() {
    const {data, period} = this.state;

    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Error Log</strong>,
      width: '950px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <ErrorLogPanel
        type='widget'
        id={data.widget.id}
        period={period}
        onConfirm={() => null}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }
  showClickDetails(d) {
    const {data, period} = this.state;
    const {widget} = data || {};
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>Click Logs for {widget.name}</strong>,
      width: '1000px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: <ClickLogPanel
        type='widget'
        id={widget.id}
        period={period}
        onConfirm={() => null}
        onCancel={() => {
          CondirmDialog.closeAll();
        }}
      />,
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }

  duplicateWidget(d) {
    this.setState({loading: true})
    return post('/api/duplicateWidget', {id: d.id}).then(newWidget => {
      this.setState({loading: false});
      this.props.history.push(`/widget/create/${newWidget.id}?clone=y`);
    }).catch(e => {
      this.setState({loading: false});
      console.error(e);
    })
  }

  deleteWidget(d) {
    CondirmDialog.showConfirm(`Do you really want to delete widget ${d.name || d.id}?`, () => {
      this.setState({loading: true})
      return post('/api/deleteWidget', {id: d.id}).then(results => {
        this.setState({loading: false});
        this.props.history.push(`/widget`);
      }).catch(e => {
        this.setState({loading: false});
        console.error(e);
      })
    });

  }

  showDeeperInsights(metric) {
    const {data, period} = this.state;
    const {widget} = data || {};
    const metricKey = metric.replace('Stat', '');
    const metricMeta = metrics.find(m => m.key === metricKey);
    const engine = widget.thinkWidget ? 'ta' : 'boost';
    const confirmInfo = {
      type: 'form',
      backgroundClose: true,
      title: <strong>{metricMeta.label} Insights</strong>,
      width: '500px',
      hideCancel: true,
      hideOK: true,
      onCancel: () => CondirmDialog.closeAll(),
      dialogBody: (
        <div style={{overflow: 'auto', maxHeight: '80vh'}}>
          <InsightExplorer
            period={period}
            type='widget'
            widget={widget}
            metric={metricKey}
            engine={engine}
            onConfirm={() => null}
            onCancel={() => {
              CondirmDialog.closeAll();
            }}
          />
        </div>
      )
    }
    ReactDOM.render(<CondirmDialog {...confirmInfo} />, document.getElementById('global-confirm'));
  }
}))
