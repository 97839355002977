import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {GridContainer} from '../uikit/grid/index.style';
import {
  PrimaryDarkBlue, PrimaryMediumBlue, SecondaryMediumBlue, PrimaryLightBlue,
  GrapeFruit, MediumGray1, DarkGray1, LightGray, DarkGray, LightGray1, BlueJeans,
  MediumGray, BitterSweet, Grass1, Sunflower, LightGray2, Grass, BlueJeans1
} from '../app/StyleCommon';
import {SelectStyled} from '../widgets/select.style';

export const ChartWrapper = styled.div`
  .chart-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .chart-type-selector {
    display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid ${MediumGray};
    padding-bottom: 5px;
    > div {
      width: 50%;
      padding: 5px;
      cursor: pointer;
      color: ${DarkGray};
      white-space: nowrap;
      &:hover {
        background: ${LightGray1};
      }

      &.selected {
        background: ${Grass};
        color: white;
      }
    }
  }

  .chart-legent {
    display: flex;
    align-items: center;
    color: ${DarkGray1};
    flex-wrap: wrap;
    line-height: 1.5em;

    .legent-item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      cursor: pointer;
      font-size: 12px;

      &.none {
        color: ${MediumGray} !important;
      }

      &.none .rect,
      &.none .line {
        background-color: ${MediumGray1} !important;

        &:before {
          background-color: ${MediumGray1} !important;
        }
      }

      &.inactive .rect,
      &.inactive .line {
        background-color: ${MediumGray} !important;
        color: ${MediumGray} !important;
      }

      .rect {
        width: 10px;
        height: 14px;
        border-radius: 2px;
        background-color: ${LightGray1};
        margin-right: 5px;
      }

      .line {
        width: 20px;
        height: 2px;
        border-radius: 5px;
        background-color: ${BlueJeans};
        position: relative;

        &:before {
          position: absolute;
          left: 6px;
          top: -3px;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: inherit;
        }
      }
    }
  }

  .chart-actions {
    flex: none;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;

    .select-box {
      .menu {
        right: 20px !important;
        left: auto !important;
        min-width: 150px;
        max-height: 400px;
        overflow: auto;
      }
    }

    @media (max-width: 667px) {
      margin: 5px 10px;
      justify-content: flex-start;
    }
  }

  .select-btn {
    font-size: 12px !important;
    padding: 3px 8px !important;
    min-width: inherit !important;
    white-space: nowrap;

    &.none {
      background: ${Grass};
    }
  }
`;
export const UnitItem = styled.span`
  padding: 0 5px;
  cursor: pointer;
  color: ${DarkGray};
  &:after {
    content: '';
    display: inline-block;
    height: 10px;
    width: 2px;
    margin-left: 8px;
    background: ${MediumGray};
  }
  &:last-child:after {
    display: none;
  }
  &.selected {
    color: ${BlueJeans1};
    font-weight: bold;
  }
`;
