import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router';
import {withConsumer} from '../app/ApplicationContext';
import {Wrapper} from "./index.style";
import TopVideos from "./topVideos";
import PieChart from "./breakdownPie";
import Breakdown from "./breakdown";
import BreakdownHorizontal from "./breakdown_horizontal";
import {ProgressBar} from "../uikit";
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import {GridContainer} from "../uikit/grid/index.style";
import {engineMap, metricMap, metrics} from "../utils/metadata";
import {SelectInlineStyled} from "../dashboard/index.style";

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      metric: props.metric
    };
  }

  buildQuery() {
    const {abtesting, engine, metric, period} = this.props;
    const {metric: stateMetric} = this.state;
    return {
      abtesting_id: abtesting ? abtesting.id : undefined,
      engine,
      metric: stateMetric || metric,
      period
    };
  }

  render() {
    const {abtesting, engine} = this.props;
    const {data, error, loading, metric} = this.state;
    const metricMeta = metrics.find(d => d.key === metric);
    const metricNameMap = {
      impressions: 'recommended',
      ctr: 'click through',
      video_starts: 'Video View',
      vcr: 'Completion Rate',
      vv_per_click: 'VV/Clicks',
      'uvv': 'Unique Video Viewers',
      video_view_duration: 'Total Watch Time',
      'avg_view_duration': 'Watch Time per VV',
      'watch_time': 'Avg Watch Time per UVV'
    };
    const action = metricNameMap[metric];
    let isPerformanceMetric = !['impressions', 'video_starts'].includes(metric);
    const charts = [
      {title: `Top ${action} videos`, Component: TopVideos, dataKey: 'topVideos'},
      // {title: `Top ${action} genres`, Component: Breakdown, dataKey: 'topGenres'},
      {title: `${metricMeta.label} by User type`, Component: isPerformanceMetric ? Breakdown : PieChart, dataKey: 'userTypes'},
      {title: `${metricMeta.label} by Device Type`, Component: BreakdownHorizontal, dataKey: 'deviceTypes'},
      {title: `${metricMeta.label} by User Segment`, Component: BreakdownHorizontal, dataKey: 'userRetentions'},
    ]
    return (
      <Wrapper>
        {
          !data && !error &&
          <ProgressBar
            withoutCache={true}
            fixcenter={true}
            url={`/api/queryMewatchInsights`}
            params={this.buildQuery()}
            successHandler={data => this.setState({data: data})}
            errorHandler={e => this.setState({error: e.message})}/>
        }
        {
          !!error && <ErrorMessage message={error}/>
        }
        {
          !!data && engine !== 'both' &&
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <strong>{engineMap[engine]}</strong>
              <SelectInlineStyled
                useFixed={true}
                selected={metric}
                data={Object.keys(metricNameMap).map(k => ({key: k, label: metricMap[k]}))}
                onChange={m => {
                  this.setState({metric: m, data: null})
                }}/>
              Distribution
            </div>
            <GridContainer type="1-1">
              <div>
                {
                  charts.slice(0, 1).map(chart => {
                    let Component = chart.Component;
                    let chartData = (data[engine] || {})[chart.dataKey];
                    if(!chartData || !chartData.length) return null;
                    return (
                      <div key={chart.title}>
                        <h3>{chart.title}</h3>
                        <Component data={chartData} metric={metric}/>
                      </div>
                    );
                  })
                }
              </div>
              <div>
                {
                  charts.slice(1).map(chart => {
                    let Component = chart.Component;
                    let chartData = (data[engine] || {})[chart.dataKey];
                    if(!chartData || !chartData.length) return null;
                    return (
                      <div key={chart.title}>
                        <h3>{chart.title}</h3>
                        <Component data={chartData} metric={metric}/>
                      </div>
                    );
                  })
                }
              </div>
            </GridContainer>
          </div>
        }
        {
          !!data && engine === 'both' &&
          <div>
            <div style={{display: 'flex', alignItems: 'center', borderBottom: '1px solid #F5F7FA', padding: '5px 5px', marginBottom: '15px'}}>
              Metric:
              <SelectInlineStyled
                useFixed={true}
                selected={metric}
                data={Object.keys(metricNameMap).map(k => ({key: k, label: metricMap[k]}))}
                onChange={m => {
                  this.setState({metric: m, data: null})
                }}/>
            </div>
            <GridContainer type="1-1" style={{marginBottom: '-20px', padding: '0 5px'}}>
              <div><strong>BOOST</strong></div>
              <div><strong>TA</strong></div>
            </GridContainer>
            {
              charts.map(chart => {
                let Component = chart.Component;
                return (
                  <div key={chart.title}>
                    <h3>{chart.title}</h3>
                    <GridContainer type="1-1" >
                      <Component data={data.boost[chart.dataKey]} metric={metric} style={{paddingRight: '20px', borderRight: '1px solid #f1f2f3'}}/>
                      <Component data={data.ta[chart.dataKey]} metric={metric} style={{paddingLeft: '20px'}}/>
                    </GridContainer>
                  </div>
                );
              })
            }
          </div>
        }
      </Wrapper>
    )
  }
})
