import React from 'react';
import ReactServer from 'react-dom/server';
import _ from 'lodash';
import moment from 'moment';
import {Redirect, Link} from 'react-router-dom';
import {post} from "../utils/request";
import {dashboardCards, timeseriesDimensions} from './metadata';
import {datePeriodWindows, algorithms} from '../utils/metadata';
import {SelectStyled} from '../widgets/select.style';
import SiteMapSelector from '../uikit/selectbox/siteMapSelector';
import {withConsumer} from '../app/ApplicationContext';
import MainLayout from '../layout/index';
import {ProgressBar, Table, TableScrollWrapper, DateSelectorDropdown, Transition, Hint} from '../uikit/index';
import {CardTitle, DashboardWrapper, Card, WidgetStats, Histogram,} from './index.style';
import {KeyMetricsComparison, ContentTooltipWrapper, BarWrapper, SelectInlineStyled} from './index.style';
import dashboardMetadata from './metadata';
import {RecentActivity, TopRecommends, WidgetStatus, TopWidgets, PaginationStyled} from './index.style';
import {GridContainer} from '../uikit/grid/index.style';
import createTooltip from '../uikit/tooltip/index';
import Sparkline from './sparkline';
import {BlueJeans, colorCategory1, DarkGray, MediumGray1} from '../app/StyleCommon';
import Pie from './pie';
import HistogramTimeseries from './histogram';
import Daypart from './daypart';
import PerformanceTable from './performance';
import TopABTestingTable from './topAbtestings';
import {duration, filterTags, intFormatter, pct2Formatter, pctFormatter, periodFormat} from "../utils/formatter";
import {getFromCache, cacheResource} from '../utils/cache'
import {ErrorMessage} from "../uikit/errorbox/errorMessage";
import MewatchDashboard from "./index.mewatch";

const FILTER_CACHE_KEY = 'dashboard_settings';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    const {appState: {session}} = this.props;
    const cachedSettings = getFromCache(FILTER_CACHE_KEY) || {};
    this.state = {
      period: 'last30days',
      selectedSites: cachedSettings.sites || (session.sites.map(s => s.key)),
      sections: undefined,
      timeseriesDimension: 'site',
      twSortBy: 'impressions', //top widgets
      twSortDirection: 'desc', //top widgets
      twPage: 1, //top widgets
      daypartMetric: 'impressions'
    };
  }

  componentDidMount() {
    // this.realtimeTimer = setInterval(() => {
    //   const query = this.buildInsightQuery();
    //   post('/api/queryDashboardNew', query).then(newData => (this.setState({data: newData}))).catch(e => null);
    // }, 300000);
  }

  componentWillUnmount() {
    // this.realtimeTimer && clearInterval(this.realtimeTimer);
  }

  buildInsightQuery() {
    return {...(_.omit(this.state, ['selectedSites', 'loading', 'data'])), sites: this.state.selectedSites};
  }

  render() {
    const {readOnly, appState: {session = {}} = {}} = this.props;
    const {data, error, loading, selectedSites, sections, period} = this.state;

    const {timeseriesDimension, twSortBy, twSortDirection, twPage, daypartMetric} = this.state;
    const {
      siteMap = [],
      keyMetrics = {},
      histogramBreakDown = {},
      abtestingLiftReport = {},
      topABTestings = {},
      daypartReport = {}
    } = data || {};

    if(selectedSites.length === 1 && ['mewatch', 'preprod_mewatch'].includes(selectedSites[0])) {
      // if(true) {
      return <MewatchDashboard />;
    }

    return (
      <MainLayout activeItem="dashboard" loading={loading}>
        <DashboardWrapper>
          {
            !data && !error &&
            <ProgressBar
              withoutCache={true}
              fixcenter={true}
              url='/api/queryDashboardNew'
              params={this.buildInsightQuery()}
              successHandler={data => this.setState({data: data})}
              errorHandler={e => this.setState({error: e.message})}/>
          }
          <div className="page-header">
            <h3>Dashboard</h3>
            <div className="filters">
              <DateSelectorDropdown
                mode='range'
                placeholder='Select Period'
                period={period}
                shortcuts={datePeriodWindows}
                historyDateAllowed={true}
                onDateRangeUpdated={period => this.setState({period: period, data: null, error: null})}/>

              <SiteMapSelector
                selected={selectedSites}
                sections={sections}
                labelPrefix={<i className="fa fa-sitemap" style={{color: DarkGray, marginRight: '10px'}}/>}
                defaultLabel={'All Sites'}
                data={siteMap}
                onChange={s => {
                  cacheResource(FILTER_CACHE_KEY, {sites: s})
                  this.setState({selectedSites: s})
                  if (s && s.length) {
                    this.setState({data: null}); //reload
                  }
                }}
                onSectionsChange={sections => {
                  console.log(sections);
                  this.setState({sections: sections, data: null})
                }}/>
            </div>
          </div>
          {
            !!error &&
            <ErrorMessage message={error}/>
          }
          {
            !!data &&
            <React.Fragment>
              <GridContainer type={'1-1-1-1'}>
                {!!data && dashboardCards.map(({formatter, key, label, color, icon}) => {
                  let keyMetricData = keyMetrics[key];
                  let value = keyMetricData;
                  let trending = null;
                  if (keyMetricData && typeof keyMetricData === 'object') {
                    value = keyMetricData.value;
                    trending = keyMetricData.data;
                  }
                  if(!session.isAdmin && key === 'errorRateStat') {
                    return null;
                  } else if(session.isAdmin && key === 'clickStat') {
                    return null;
                  }
                  return (
                    <Card className="card metric-card" key={key + value} type={color}>
                      <WidgetStats>
                        <div className="top">
                          <i className={icon} aria-hidden="true"></i>
                          <span>{label}</span>
                        </div>
                        <div className="middle">
                          <h3><Transition>{formatter(value)}</Transition></h3>
                          <div className="rate">
                            <Sparkline data={trending} formatter={formatter} period={period}/>
                          </div>
                        </div>
                      </WidgetStats>
                    </Card>
                  )
                })}
              </GridContainer>
              <Card className="card">
                <Histogram>
                  <CardTitle className="title-wrapper">
                    <span className="title">
                      <span>Impressions by</span>
                        <SelectInlineStyled
                          selected={timeseriesDimension}
                          groups={timeseriesDimensions}
                          mobileAdjust={true}
                          useFixed={true}
                          itemRenderer={d => {
                            return (
                              <div className="dimension-item" key={d.key}>
                                <img src={d.icon}/>
                                <div>
                                  <div className="name">{d.label}</div>
                                  <div className="description">{d.description}</div>
                                </div>
                              </div>
                            )
                          }}
                          onChange={dimension => {
                            this.setState({timeseriesDimension: dimension}, () => this.refreshChart('histogramBreakDown'))
                          }}/>
                    </span>
                  </CardTitle>
                  <div className="middle">
                    {
                      !!histogramBreakDown &&
                      <HistogramTimeseries
                        key={histogramBreakDown.dimension + histogramBreakDown.timestamp}
                        dimension={histogramBreakDown.dimension}
                        siteMap={siteMap}
                        availableMetrics={[
                          'impressions', 'clicks', 'video_starts','video_completions',
                          'watch_time', 'subscribes', 'bookmarks', 'downloads',
                          'ctr', 'vpr', 'vcr', 'vv_per_click'
                        ]}
                        data={histogramBreakDown}/>
                    }
                  </div>
                </Histogram>
              </Card>
              <Card className="card">
                <CardTitle className="title-wrapper flex">
                    <span className="title">
                      <span className="blockInMobile">
                        Widget Performance
                        <Hint pos="right"
                              useFixed={true} width={250} content={
                          <div style={{textAlign: 'left', color: 'white'}}>
                            Compare metrics within selected date range. Sort metrics to identify best and worst performing widgets.
                          </div>
                        }/>
                      </span>
                    </span>
                  <div style={{textAlign: 'right'}}>
                    <div>{periodFormat(period)}</div>
                    <div style={{fontSize: '11px'}}>compare within date range</div>
                  </div>
                </CardTitle>
                <div className="middle">
                  {<TopABTestingTable
                    key={topABTestings.timestamp}
                    data={topABTestings}
                    period={period}
                    config={{twSortBy, twSortDirection, twPage}}
                    refreshChart={this.refreshChartWithState.bind(this)}/>}
                </div>
              </Card>
              {/*{*/}
              {/*  !session.isExternal && session.isAdmin &&*/}
              {/*  <Card className="card">*/}
              {/*    <CardTitle className="title-wrapper flex">*/}
              {/*      <span className="title">*/}
              {/*        <span className="blockInMobile">*/}
              {/*          Model Performance*/}
              {/*          <Hint pos="right"*/}
              {/*                useFixed={true} width={200} content={*/}
              {/*            <div style={{textAlign: 'left', color: 'white'}}>*/}
              {/*              CTR Lift are caculated based on A/B testing against Cxense Widget.*/}
              {/*              Click the + icon to view model level CTR Lift information.*/}
              {/*            </div>*/}
              {/*          }/>*/}
              {/*        </span>*/}
              {/*      </span>*/}
              {/*      <div style={{textAlign: 'right'}}>*/}
              {/*        <div>{periodFormat(period)}</div>*/}
              {/*        /!*<div style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>*!/*/}
              {/*        /!*  <label>*!/*/}
              {/*        /!*    <input type="checkbox"  onClick={e => window.alert('Not implemented yet, TODO')}/> Ignore fallback traffic*!/*/}
              {/*        /!*  </label>*!/*/}
              {/*        /!*</div>*!/*/}
              {/*      </div>*/}
              {/*    </CardTitle>*/}
              {/*    <div className="middle">*/}
              {/*      {*/}
              {/*        <PerformanceTable*/}
              {/*          period={period}*/}
              {/*          refreshChart={this.refreshChartWithState.bind(this)}*/}
              {/*          data={abtestingLiftReport.liftConfig}/>*/}
              {/*      }*/}
              {/*    </div>*/}
              {/*  </Card>*/}
              {/*}*/}

              <Card className="card">
                <CardTitle className="title-wrapper flex">
                    <span className="title">
                      <SelectInlineStyled
                        selected={daypartMetric}
                        data={_.compact([
                          {key: 'impressions', label: 'Impressions'},
                          {key: 'ctr', label: 'CTR'},
                          // {key: 'response_time', label: 'response_time'},
                          !session.isAdmin ? null : {key: 'error_rate', label: 'Timeouts'},
                        ])}
                        mobileAdjust={true}
                        useFixed={true}
                        onChange={metric => {
                          this.setState({daypartMetric: metric})
                        }}/>
                      <span>
                        Heatmap
                        <Hint pos="right"
                              useFixed={true} width={200} content={
                          <div style={{textAlign: 'left', color: 'white'}}>
                            Average hourly breakdown of the selected metric for each day of the week
                          </div>
                        }/>
                      </span>
                    </span>
                  <div style={{textAlign: 'right'}}>
                    <div>{periodFormat(period)}</div>
                  </div>
                </CardTitle>
                <div className="middle">
                  <Daypart
                    key={daypartReport.timestamp + daypartMetric}
                    metric={daypartMetric}
                    data={daypartReport}/>
                </div>
              </Card>
            </React.Fragment>
          }
        </DashboardWrapper>
      </MainLayout>
    )
  }

  refreshChartWithState(insightType, changedState) {
    this.setState(changedState, () => {
      this.refreshChart(insightType);
    });
  }

  refreshChart(insightType) {
    this.setState({loading: true});
    const query = this.buildInsightQuery();
    query.requiredInsights = [insightType];
    post('/api/queryDashboardNew', query).then(newData => {
      let {data: oldData} = this.state;
      this.setState({data: {...oldData, ...newData}, loading: false});
    }).catch(e => {
      this.setState({loading: false})
    });
  }
})

