import React, {Component, useEffect} from 'react';
import ReactServer from 'react-dom/server';
import createTooltip from '../uikit/tooltip/index';
import * as echarts from 'echarts';
import classnames from 'classnames';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import {ReRenderHook} from '../utils/hooks';
import {
  BitterSweet,
  BitterSweet1,
  BlueJeans, BlueJeans0,
  BlueJeans1, colorCategory1, DarkGray, GrapeFruit, Grass,
  Grass1,
  LightBlue,
  LightGray,
  LightGray1, MediumGray, MediumGray1,
  Sunflower,
  Sunflower1
} from "../app/StyleCommon";
import {
  filterTags,
  intFormatter,
  pct0Formatter,
  pct2Formatter,
  pctFormatter,
  responseTimeFormatter,
  uniquesFormatter
} from "../utils/formatter";
import {Table, TableScrollWrapper} from "../uikit";
import {BarWrapper} from "../home/index.style";
import Sparkline3 from "./sparkline3";
import {Card, CardTitle, PaginationStyled, TopWidgets} from "./index.style";
import {Link} from "react-router-dom";
import {Taglist} from "../widgets/index.style";
import {engineMap, metricMap, metrics} from "../utils/metadata";
import {withConsumer} from "../app/ApplicationContext";


const MetricWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  //justify-content: space-evenly;
  justify-content: flex-start;
  //background: ${LightGray};
  padding: 0 5px 5px 10px;
  .num {
    margin-right: 10px;
  }
  .marker {
    width: 10px;
    height: 8px;
    margin-left: 4px;
    margin-right: 6px;
    max-width: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    &.gray {
      filter: grayscale(100);
    }
    &.revert {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }
  }
`

export default withConsumer(class extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMap: {},
      page: 1,
      pageSize: 15,
      sortBy: (props.config || {}).widgetSortBy || 'impressions',
      sortDirection: (props.config || {}).widgetSortDirection || 'desc',
    };
  }

  render() {
    const {appState: {session = {}}, hideColdStart} = this.props;
    const {isAdmin, isOperator} = session || {};
    const {data, config, period} = this.props;
    const {total,items,} = data || {};
    const {pageSize, page, sortBy, sortDirection} = this.state;
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().add(-1, 'day').format('YYYY-MM-DD');
    let filteredItems = _.sortBy(items, d => {
      // return d[sortBy];
      if(sortBy === 'engine') {
        return _.sum(d.breakdown.map(b => b.impressions));
      } else {
        return _.sum(d.breakdown.map(b => b[sortBy]));
      }
    }).filter(d => {
      if(hideColdStart && d.abtesting_id === 'Pnn1jbHyoo') {
        return false;
      }
      return true;
    });
    if(sortDirection === 'desc' || sortBy === 'engine') {
      filteredItems = filteredItems.reverse();
    }
    filteredItems = filteredItems.slice((page - 1) * pageSize, page * pageSize);
    const expendedRows = _.flatten((filteredItems || []).map(d => {
      return _.compact([
        d,
        ...(_.sortBy((d.breakdown || []).map(p => {
          return {...p, key: p.engine, name: p.engine, isChild: true}
        }), b => {
          if(sortBy === 'engine') {
            return (b[sortBy] === 'boost' ? -1 : 1) * (sortDirection === 'desc' ? -1 : 1)
          } else {
            return b[sortBy] * (sortDirection === 'desc' ? -1 : 1)
          }
        }))
      ]);
    }));
    return (
      <TopWidgets className="ta-boost-top-widgets">
        <TableScrollWrapper className="middle">
          <Table
            columns={[
              {
                key: 'engine',
                label: 'A/B Test Name',
                style: {verticalAlign: 'top'},
                sortable: true,
                styleFunc: d => {
                  if(!d.isChild) {
                    return {background: '#fafbfc'};
                  }
                },
                renderer: (d, dIndex) => {
                  let nextRow = expendedRows[dIndex + 1] || {};
                  return (
                    <div className={`widget-info ${d.isChild ? 'child' : ''} ${nextRow.isChild ? 'withAfter' : ''}`}>
                      <div className="top">
                        {d.isChild && <span style={{marginLeft: '30px'}}> &nbsp;&nbsp;&nbsp;&nbsp;</span>}
                        {!d.isChild && <span style={{marginRight: '6px', marginLeft: '5px'}}>
                          <Link to={`/abtesting/${d.abtesting_id}`}>
                          <i className="fa fa-thermometer-full"  style={{marginRight: '5px'}}/> {d.name}
                          </Link>
                          {
                            !!filterTags(d.tags, 'abtesting', isAdmin).length &&
                            <div>
                              <Taglist className="tag-list"  style={{marginLeft: '15px'}}>
                                {filterTags(d.tags, 'abtesting', isAdmin).map(t => {
                                  return <span className={`tag ${t.split('::').join(' ')} `} key={t}>{t.split('::').pop()}</span>
                                })}
                                {/*{!filterTags(d.tags, 'abtesting', isAdmin).length && '--'}*/}
                              </Taglist>
                            </div>
                          }
                        </span>}
                        {d.isChild && <span style={{marginRight: '6px'}}>
                          <img className="publisher" src={`/img/property/${d.engine}.png`} style={{height: '20px', width: 'auto', verticalAlign: 'middle'}}></img>
                          {engineMap[d.name] || 'Boost'}
                        </span>}
                      </div>
                    </div>
                  )
                }
              },
              ...(['impressions', 'ctr', 'video_starts','vv_per_click','vcr',  'uvv', 'video_view_duration', 'avg_view_duration', 'watch_time',].map(metric => {
                let metricMeta = metrics.find(m => m.key === metric);
                let {priority} = metricMeta || {};
                if(metric === 'uvv') {
                  priority = 'M0'; // special case
                }
                return {
                  key: metric,
                  label: (
                    <div>
                      <div>{metricMap[metric]}</div>
                      {!!priority &&  <div style={{color: MediumGray1, fontSize: '11px', fontWeight: 'normal'}}> ({priority})</div>}
                    </div>
                  ),
                  align: 'left',
                  style: {verticalAlign: 'top'},
                  // style: {minWidth: '100px'},
                  sortable: true,
                  styleFunc: d => {
                    if(!d.isChild) {
                      return {background: '#fafbfc'};
                    }
                  },
                  renderer: d => {
                    if(!d.isChild) return <div style={{width: '100%', height: '12px'}} onClick={e => {
                      //this.props.showDeeperInsights(metric, 'both')
                    }}></div>;
                    let allItems = _.flattenDeep(items.map(d => d.breakdown || []));
                    let max = _.max(allItems.map(item => item[metric] || 0));
                    return (
                      <MetricWrapper onClick={e => this.props.showDeeperInsights(metric, d.engine)}>
                        {/*<div className="num">{metricMeta.formatter(d[metric])}</div>*/}
                        <BarWrapper className="small bar-wrapper">
                          <div className="num">{metricMeta.formatter(d[metric])}</div>
                          <div className="bar"
                               style={{width: pctFormatter(d[metric] / max)}}></div>
                        </BarWrapper>
                        {/*<Sparkline3*/}
                        {/*  period={period}*/}
                        {/*  hideVisualMap={true}*/}
                        {/*  width={40}*/}
                        {/*  height={20}*/}
                        {/*  data={(d.trend || []).map(item => item[metric] || 0)}*/}
                        {/*  formatter={metricMeta.formatter}/>*/}
                      </MetricWrapper>
                    )
                  }
                };
              })),
            ]}
            rows={expendedRows}
            sort={{sortColumn: sortBy, direction: sortDirection}}
            sortChange={orderBy => {
              this.setState({sortBy: orderBy, page: 1})
            }}
            directionChange={direction => {
              this.setState({sortDirection: direction, page: 1})
            }}
            noDataText={"No Widgets Found"}
          />
        </TableScrollWrapper>
        <PaginationStyled
          onChange={p => this.setState({page: p})}
          current={page}
          pageSize={pageSize}
          total={total}
          hideOnSinglePage={true}/>
      </TopWidgets>
    )
  }
})
