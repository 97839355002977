import styled, {css} from 'styled-components';
import Pagination from 'rc-pagination';
import {GridContainer} from '../uikit/grid/index.style';
import {
  PrimaryDarkBlue, PrimaryMediumBlue, SecondaryMediumBlue, PrimaryLightBlue,
  GrapeFruit, MediumGray1, DarkGray1, LightGray, DarkGray, LightGray1, BlueJeans,
  MediumGray, BitterSweet, Grass1, Sunflower, LightGray2, Grass
} from '../app/StyleCommon';
import {SelectStyled} from '../widgets/select.style';

export const CardTitle = styled.div`
  color: ${DarkGray1};
  padding: 15px 0;
  font-size: 14px;
  &.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-weight: bold;
    display: flex;
    align-items: center;
     @media screen and (max-width: 667px) {
      display: block;
      >span.blockInMobile {
        display: block;
        margin: 10px 0 0 10px;
      }
     }
  }
`;


export const SelectInlineStyled = styled(SelectStyled)`
  .toggler {
    border: none;
    display: inline;
    background: none;
    padding: 0 30px 0 5px;
    margin-left: 5px;
    border-radius: 0;
    // color: ${BlueJeans};
    //border-bottom: 1px dotted ${MediumGray};
    &:hover {
      background: none;
    }
    img {
      display: none;
    }
  }
  .menu {
    top: 25px;
    text-align: left;
    right: auto !important;
    ${props => props.mobileAdjust && css`
      @media screen and (max-width: 667px) {
        left: 50px !important;
        right: 10px !important;
        width: calc(100vw - 60px) !important;
      }
    `}

    .dimension-item {
        display: flex;
        align-items: flex-start;
        padding: 5px 20px;
        white-space: normal;
        padding: 5px 0;
        width: 300px;
        img {
          height: 16px;
          margin-right: 10px;
          margin-top: 3px;
        }
        .description {
           font-size: 11px;
           color: ${DarkGray1};
           font-weight: 300;
        }
    }
  }
`

export const DashboardWrapper = styled.div`
  padding: 20px;
  @media screen and (max-width: 667px) {padding: 20px 10px 20px 5px;}
  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    @media screen and (max-width: 667px) {display: block;}
    > h3 {
      margin: 0;
      padding: 0;

    }
    .filters {
      display: flex;
      align-items: center;
      @media screen and (max-width: 667px) {
        display: block;
        .select-box {
          width: 100%; margin: 10px 0;
          .toggler {
            width: 100%;
          }
          .date-selector {
            flex-wrap: wrap !important;
          }
          .month-panel {
            width: 100%  !important;
          }
        }
      }
      .toggler {
        padding: 8px 10px;
      }

      .date-selector-dropdown {
        min-width: 250px;
        margin-right: 20px;
        @media screen and (max-width: 667px) {
          .date-selector {
            flex-wrap: wrap !important;
          }
          .month-panel {
            width: 100% !important;
          }
        }
      }
    }
  }
  ${GridContainer} {
    margin-bottom: 20px;
    .card {
      margin-bottom: 0;
      @media screen and (max-width: 667px) {
        margin-bottom: 15px;
      }
    }
  }
`;

export const Card = styled.div`
  width: 100%;
  color: white;
  padding: 10px 12px;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 0 rgba(10, 10, 10, 0.11);
  background-color: #fff;
  margin-top: 20px;
  &.metric-card {
    margin-bottom: 10px !important;
  }
  ${props => props.type === 'blue1' && css`
    background-color: ${PrimaryDarkBlue};
  `}
  ${props => props.type === 'blue2' && css`
    background-color: #3385b8;

  `}
  ${props => props.type === 'blue3' && css`
    background-color: ${SecondaryMediumBlue};


  `}
  ${props => props.type === 'blue4' && css`
    background-color: ${PrimaryMediumBlue};
  `}
  ${props => props.type === 'blue5' && css`
    background-color: ${PrimaryLightBlue};
  `}
  ${props => props.type === 'green1' && css`
    background-color: ${Grass};
  `}
  ${props => props.type === 'red1' && css`
    background-color: ${BitterSweet};
  `}
`;

export const WidgetStats = styled.div`
  .top {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
    i {
      margin-right: 10px;
      font-size: 20px;
    }
  }
  .middle {
    display: flex;
    align-items: center;
    height: 60px;
    > h3 {
      margin-right: 10px;
      font-size: 24px;
      font-weight: 500;
      min-width: 30px;
      white-space: nowrap;
    }
    > div {
      padding: 2px 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 12px;
      .status, .value {
        margin-right: 5px;
      }
      .arrow {
        font-size: 10px;
      }
    }
  }
`;

export const TopWidgets = styled.div`
  color: ${DarkGray1};

  &.ta-boost-top-widgets table td {
    text-align: left;
  }

  &.engine-report {
    table {
      thead tr:first-child th {
          //background-color: ${LightGray};
        &:nth-child(2) {
            //background-color: ${LightGray};
          //border-left: 1px dashed transparent !important;
        }

        &:nth-child(3) {
          border-left: 1px dashed ${MediumGray1};
        }
      }

      thead tr:nth-child(2) th {
        background-color: #fafbfc;
        padding-right: 10px;
        &:nth-child(2) {
          padding-right: 12px;
        }
      }

      thead tr:nth-child(n+2) th,
      tbody tr td {
        padding: 10px 8px;

        &:nth-child(1) {
          border-right: 1px solid rgba(204, 209, 217, 0.53);
        }

        &:nth-child(11) {
          border-right: 1px dashed ${MediumGray1};
        }

        .metric-item {
          flex-wrap: wrap;
          flex-direction: column;
        }
      }

      tbody tr:nth-child(2) td {
          //border-bottom: 1px dashed ${MediumGray1};
      }

    }
  }

  .title-wrapper {
    padding: 15px 0;

    p {
      margin: 5px 0 0 0;
    }

    .title {
      font-size: 14px;
    }
  }

  .middle {
    min-height: 240px;

    table {
      border: none;

      thead {
        //display: none;
        color: ${DarkGray};

        th {
          border: none;
          background-color: white;
          border-bottom: 1px solid ${MediumGray};
        }
      }

      tbody {
        tr td {
          padding: 10px 5px;
          vertical-align: top;
          background: white;
          border-right: 1px solid #f7f8fb;

          &:last-child {
            border-right: none;
          }
        }

        tr {
          background: white;
          border-top: 1px solid ${LightGray};
        }
      }

      .widget-info {
        min-width: 200px;

        &.child {
          position: relative;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 30px;
            border-left: 1px solid ${MediumGray};
            border-bottom: 1px solid ${MediumGray};
            left: 25px;
            bottom: 8px;
          }

          &.withAfter:after {
            content: "";
            display: block;
            position: absolute;
            width: 12px;
            height: 25px;
            border-left: 1px solid ${MediumGray};
            left: 25px;
            bottom: -18px;
          }
        }

        .title-text {
          display: block;
        }

        .tag-list {
          display: inline;
          padding: 0 !important;
          flex-wrap: wrap;

          .tag {
            border: 1px solid ${LightGray1};
            border-radius: 5px;
            font-size: 10px;
            font-weight: normal;
            display: inline !important;;
            color: #1f2936;
            //background: #dbf1c4;
            background: #fafbfc;
            margin: 5px 3px 5px 3px !important;
            padding: 0 3px !important;

            &.platform {
              background: #57c5f766;
            }

            &.page {
              background: #a0d46894;
            }

            &.algo {
              background: #ffce54ab;
            }
          }
        }

        .top {
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: auto;
            margin-right: 5px;
          }

          .title {
            white-space: normal;
            word-break: break-word;
            min-width: 90px;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }

            i {
              padding: 0 5px;
            }
          }
        }

        .bottom {
          font-size: 12px;
          color: ${MediumGray1};
          margin-left: 30px;
        }

      }
    }
  }
`;

export const BarWrapper = styled.div`
  position: relative;
  line-height: 12px;
  text-align: left;
  width: 80px;

  &.small {
    width: 60px;
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      filter: drop-shadow(0px 1px 1px #c9dbf3);
    }
  }

  .bar {
    background: #6fb2f6;
    height: 14px;
    border-radius: 3px;
    //position: absolute;
    min-width: 1px;
    top: 0;
    z-index: 0;
    height: 3px;
    margin-top: 3px;
  }

  .num {
    display: block;
    font-size: 12px;
    position: relative;
    z-index: 1;
  }

  .desc {
    font-size: 10px;
    margin-top: 3px;
    color: ${MediumGray1};
  }
`;

export const TopRecommends = styled.div`
  color: ${DarkGray1};
  .title-wrapper {
    padding: 15px 0;
  }
  .middle {
    min-height: 380px;
    table {
      border: none;
      thead {
        color: ${DarkGray};

        th {
          border: none;
          background-color: white;
          border-bottom: 1px solid ${MediumGray};
        }
      }
      tbody {
        tr td {
          padding: 10px;
          &:first-child {
            width: 65%;
            padding-left: 0;
          }
          &:not(:first-child) {
            max-width: 40px;
          }
        }
        tr {
          background: #fff;
          border-top: 1px solid ${LightGray};
        }
      }
    }
    .content {
      display: flex;
      .publisher {
        padding: 10px;
        width: 25px;
        height: 25px;
      }
      .thumbnail-wrapper {
        margin-right: 10px;
        img {
          width: 75px;
        }
      }
      a {
        text-decoration: none;
        i {
          margin-left: 5px;
        }
        .title {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .publish_date {
          color: ${MediumGray1};
          font-size: 12px;
          text-decoration: none;
        }
      }

    }
  }
`;

export const Histogram = styled.div`
  .title-wrapper {
    .desc {
      font-size: 12px;
    }
  }
`;
export const KeyMetricsComparison = styled.div`
  color: ${DarkGray1};
  //.middle {
  //  width: 100%;
  //  padding: 10px 0;
  //  box-sizing: border-box;
  //}
  .middle {
    min-height: 280px;
    .select-box .toggler {
      border-bottom: none;
      padding: 0 30px 0 0;
      margin-left: 0;
    }
  }
  .middle > table,
  table {
    border: none;
    //min-height: 250px;
    overflow: visible;
    thead {
      color: ${DarkGray};
      th {
        text-align: left;
        border: none;
        background-color: white;
        border-bottom: 1px solid ${MediumGray};
        .select-box {margin-bottom: 1px;}
      }
    }
    tbody {
      tr td {
        padding: 10px;
        text-align: left;
        text-align: left;
      }
      tr {
        background: #fff;
        border-top: 1px solid ${LightGray};
        &.no-data td {
          text-align: center;
        }
      }
  }
`;

export const RecentActivity = styled.div`
  color: ${DarkGray1};
  .middle {
    min-height: 380px;
    padding: 10px 0;
    table {
      border: none;
      thead {
        display: none;
      }
      tbody {
        tr td {
          padding: 10px;
        }
        tr {
          background: #fff;
          border-top: 1px solid ${LightGray};
        }
      }
    }
    .initial {
      border-radius: 50%;
      width: 28px;
      height: 28px;
      font-weight: bold;
      background-color: ${BlueJeans};
      opacity: .8;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .avatar {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
    .name {
      margin: 0;
      padding: 0;
    }
    .info {
      margin: 0;
      padding: 3px 0 0 0;
      color: ${MediumGray1};
      font-size: 11px;
      a {
        margin: 0 5px;
        text-decoration: none;
        color: ${DarkGray};
        word-break: break-word;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export const WidgetStatus = styled.div`
  border-radius: 4px;
  padding: 0px 5px;
  color: #fff;
  font-weight: 600;
  ${props => props.type === 'update' && css`
    background-color: ${BlueJeans};
  `}
  ${props => props.type === 'publish' && css`
    background-color: ${Grass1};
  `}
  ${props => props.type === 'delete' && css`
    background-color: ${BitterSweet};
  `}
`;

export const PaginationStyled = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  >li {
    outline: none;
    min-width: 24px;
    min-height: 24px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    border: none;
    background: none;
    &:hover {
      text-decoration: underline;
      a {
        color: ${BlueJeans};
      }
    }
    a {
      font-size: 14px;
      line-height: 24px;
      color: ${DarkGray};
    }
    &.rc-pagination-item-active {
      a {
        color: ${BlueJeans};
      }
    }
  }
`;

export const ContentTooltipWrapper = styled.div`
  .charts {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  table {
    border: none;
    width: auto;
    flex: 1;
  }
  .rect{
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    &.green {
      background: ${Grass};
    }
    &.gray {
      background: ${MediumGray1};
    }
    &.yellow {
      background: ${Sunflower};
    }
    &.blue {
      background: ${BlueJeans};
    }
  }

`;
