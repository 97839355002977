import React from 'react';
import _ from 'lodash';
import {withConsumer} from '../app/ApplicationContext';
import {Redirect, Link} from 'react-router-dom';
import {Spinner} from '../uikit/Loader/index.style';
import {LoginWrapper, Logo, ErrorMsg, Tint} from './index.style';
import {FormWrapper, Header, ErrorMsgWrapper, FormField, SubmitBtn, BottomPart} from './index.style';
import {post} from '../utils/request';
import {SelectStyled} from './select.style';

export default withConsumer(class extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rememberMe: localStorage && localStorage.getItem('rememberMe') === 'YES'
    };
  }

  login = () => {
    let {userId, password, rememberMe} = this.state;
    userId = userId || this.refs.userInput.value;
    password = password || this.refs.passwordInput.value;
    if (!userId || !password) {
      return this.setState({error: 'Please input your account and password'});
    }
    this.setState({error: null});
    this.props.login(userId, password, rememberMe);
  }

  changeRememberMe(rememberMe) {
    this.setState({rememberMe: rememberMe});
    localStorage && localStorage.setItem('rememberMe', rememberMe ? 'YES' : 'NO');
  }

  render() {
    const {error: appError, loading, session} = this.props.appState;
    const {error, userId, password, rememberMe, formID} = this.state;
    const {from} = this.props.location.state || {from: {pathname: '/'}}
    if (session) {
      return <Redirect to={from}/>;
    }
    var isIE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);
    return (
      <LoginWrapper isIE={isIE}>
        {!isIE && <Tint/>}
        <FormWrapper
          formID={formID}
          onSubmit={e => {
            e.preventDefault();
            this.login(e)
          }}>
          <Header>Login to BOOST</Header>
          {
            !!error && <ErrorMsgWrapper>{error}</ErrorMsgWrapper>
          }
          {
            !!appError &&
            <ErrorMsgWrapper>{appError || 'Incorrect User ID and/or Password'}</ErrorMsgWrapper>
          }
          <FormField>
            <input ref="userInput"
                   value={userId}
                   type="text"
                   placeholder="Account Email"
                   onChange={e => {
                     this.setState({userId: e.currentTarget.value});
                   }}/>
          </FormField>
          <FormField>
            <input ref="passwordInput"
                   value={password}
                   type="password"
                   placeholder="Password"
                   onChange={e => this.setState({password: e.currentTarget.value})}/>
          </FormField>
          <FormField>
            <label>
              <input type="checkbox"
                     checked={rememberMe}
                     onChange={e => this.changeRememberMe(e.target.checked)}/> Stay Signed In
            </label>
          </FormField>

          <SubmitBtn>
            {
              loading &&
              <Spinner className="spinner" type="mini"/>
            }
            Sign In
          </SubmitBtn>
          <BottomPart>
            <Link to={'/forget_password'}>Forgot Password</Link>
          </BottomPart>
        </FormWrapper>

        <div className={"site-footer"}>
          <a href="https://www.mediacorp.sg/terms-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
          <a href="https://www.mediacorp.sg/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <a href="https://mediacorp.vulnerability-disclosure.com" target="_blank" rel="noopener noreferrer">Report Vulnerability</a>
        </div>
      </LoginWrapper>
    );
  }
})
