import React, {Component, useEffect} from 'react';
import _ from 'lodash';
import * as echarts from 'echarts';
import styled from "styled-components";
import {
  Aqua, Aqua1,
  BlueJeans, BlueJeans0, BlueJeans1, DarkGray,
  Grass1,
} from "../app/StyleCommon";
import {metrics} from "../utils/metadata";
import {pct0Formatter} from "../utils/formatter";

export default class extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.renderChart();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(JSON.stringify(this.props) !== JSON.stringify(prevProps) || JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.renderChart();
    }
  }

  render() {
    return (
      <ChartWrapper className="chart" style={this.props.style}>
        <div ref={this.containerRef}/>
      </ChartWrapper>
    )
  }

  renderChart() {
    let {data, metric = 'impressions'} = this.props;
    if(!data || !data.length) {
      return null;
    }
    const metricMeta = metrics.find(d => d.key === metric);
    data.forEach(d => {
      d.name = d.name || d.key;
      d.value = d[metric];
    })
    data = _.sortBy(data, _.property('value'));
    const sumOfValue = _.sumBy(data, d => Number(d.value) || 0);
    const container = this.containerRef.current;
    const myChart = echarts.init(container, null, {renderer: 'canvas', height: '200px'});
    let option = {
      textStyle: {fontFamily: '"FKGroteskNeue", "Open Sans", sans-serif'},
      // color: ['#F66D44', '#FEAE65', '#E6F69D', '#AADEA7', '#64C2A6', '#2D87BB', '#3F9F9F', '#A2C3DB', "#ea5545", "#f46a9b", "#ef9b20", "#edbf33", "#ede15b", "#bdcf32", "#87bc45", "#27aeef", "#b33dc6"],
      color: ['#f1a164', '#f4c168','#ec685c',  '#436265', '#4f8f98', '#6eb3c4','#2D6A4F', '#40916C','#532B88', '#9B72CF', Aqua1, Aqua, Grass1, Grass1, BlueJeans, BlueJeans0, BlueJeans1],
      tooltip: {
        trigger: 'item',
        position: 'top',
        confine: true,
        valueFormatter: function (value) {
          return metricMeta.formatter(value);
        }
      },
      grid: {
        top: '20',
        left: '10',
        right: '40',
        bottom: '5',
        containLabel: true,
      },
      legend: {
        left: 20,
        top: 'center',
        orient: 'vertical',
        type: 'scroll',
        itemWidth: 12 ,
        itemHeight: 10 ,
        pageIconSize: '8',
        formatter: function (name) {//use formatter to check if length of string is greater than 20 and maintain its width.
          let item = data.find(d => d.key === name || d.label === name) || {};
          return name +  ` (${metricMeta.formatter(item.value)}, ${pct0Formatter(item.value / sumOfValue)})`;
        },
        pageTextStyle: {
          lineHeight: 56,
          color: 'black'
        },
        itemStyle: {
          fontSize: 10,
        },
        tooltip: {
          show: true,
          trigger: 'item',
          position: 'top',
        },
        color: DarkGray,
        overflow: 'ellipsis',
        fontSize: 12,
      },
      series: [
        {
          type: 'pie',
          data: data.filter(d => d.key !== 'overall'),
          left: '50%',
          label: {
            position: 'inner',
            fontSize: 12,
            color: '#605e5e',
            backgroundColor: 'rgba(255,255,255,.5)',
            borderRadius: 5,
            padding: [2, 3, 3, 2],
            formatter: d => {
              let item = data.find(item => item.key === d.name) || {};
              let str = item.label || d.name || '';
              if(str.length > 10){
                return str.substring(0, 10) + '...';
              }
              return str +  ' (' + (pct0Formatter(item.value / sumOfValue)) + ')';
            }
          },
        }
      ]
    };

    myChart.setOption(option);
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  }
}

export const ChartWrapper = styled.div`
  width: 100%;
`;
