import React from 'react';
import _ from 'lodash';
import {duration, intFormatter, pct2Formatter, pctFormatter, uniquesFormatter, hmsDuration} from "../utils/formatter";
import {metrics, algorithms, widgetTypeMapping} from '../utils/metadata';
import * as d3 from 'd3';

export const dashboardCards = [
  {
    key: 'impressionStat',
    label: 'Impressions',
    color: 'blue1',
    icon: 'fa fa-eye',
    formatter: uniquesFormatter
  },
  {
    key: 'clickStat',
    label: 'Clicks',
    color: 'blue2',
    icon: 'fa fa-mouse-pointer',
    formatter: uniquesFormatter
  },
  {
    key: 'ctrStat',
    label: 'CTR',
    color: 'blue3',
    icon: 'fa fa-flag',
    formatter: pct2Formatter
  },
  {
    key: 'responseTime',
    label: 'Avg Response Time',
    color: 'blue4',
    icon: 'fa fa-hourglass',
    formatter: d => d3.format(d > 1000 * 60 ? '.5' : '.3')((d || 0) / 1000) + 's'
  },
  {
    key: 'errorRateStat',
    label: 'Timeouts',
    color: 'blue2',
    icon: 'fa fa-clock-o',
    formatter: pct2Formatter
  },
];

export const dashboardCardsMewatch = [
  {
    key: 'impressionsStat',
    label: 'Impressions',
    color: 'blue1',
    icon: 'fa fa-eye',
    formatter: uniquesFormatter
  },
  {
    key: 'ctrStat',
    label: 'CTR',
    color: 'blue2',
    icon: 'fa fa-mouse-pointer',
    formatter: pct2Formatter
  },
  {
    key: 'video_startsStat',
    label: 'Video View',
    color: 'blue3',
    icon: 'fa fa-flag',
    formatter: uniquesFormatter
  },
  {
    key: 'vcrStat',
    label: 'Completion Rate',
    color: 'blue4',
    icon: 'fa fa-flag',
    formatter: pct2Formatter
  },
  {
    key: 'watch_timeStat',
    label: 'Avg Watch Time per UVV',
    color: 'blue5',
    icon: 'fa fa-hourglass',
    formatter: hmsDuration
  }
];

export const timeseriesDimensions = [
  {
    key: 'widget_level',
    label: '',
    data: [
      {
        key: 'site', label: 'Site',
        icon: '/img/level/site.png',
        description: <span>Total number of widget impressions from each site</span>
      },
      {
        key: 'access_method', label: 'Access Method',
        icon: '/img/level/accessmethod.png',
        description: <span>Total number of widget impressions for each access type</span>
      },
      // {
      //   key: 'widget_type', label: 'Widget Type',
      //   icon: '/img/level/widget.png',
      //   description: <span>The impressions value means number of times the selected widget type is displayed</span>
      // },
    ]
  },
  // {
  //   key: 'item_level',
  //   label: 'Content Level',
  //   data: [
  //     {
  //       key: 'algorithm',
  //       label: 'Model Algorithm',
  //       c
  //       description: <span>The impressions value means count of item recommended by the model algorithm</span>
  //     },
  //     {
  //       key: 'slot_position', label: 'Slot Position',
  //       icon: '/img/level/item.png',
  //       description: <span>The impressions value means count of item displayed in the slot position</span>
  //     },
  //   ]
  // },
]

export const getDimensionKeyLabel = (dimension) => {
  let dimensions = _.flatten(timeseriesDimensions.map(d => d.data))
  let meta = dimensions.find(d => d.key === dimension);
  return meta ? meta.label : _.capitalize(dimension);
}

export const getMetricLabel = (dimension, metric) => {
  if (metric === 'impressions') {
    if (dimension === 'site') {
      return 'Impressions';
    } else if (dimension === 'type') {
      return 'Impressions';
    } else if (dimension === 'algorithm') {
      return 'Impressions';
      // return 'Recommendations';
    } else if (dimension === 'slot_position') {
      return 'Impressions';
      // return 'Recommendations';
    }

  }
  let metricMeta = metrics.find(m => m.key === metric) || {};
  return metricMeta.label || metric;
}

export const getDimensionLabel = (dimension, key, lableMap = {}, usePlain = false) => {
  if(key === 'Others') {
    return key;
  }
  const sites = window.sites || [];
  if (lableMap[key]) {
    // return key;
    if(!usePlain) {
      return (
        <span style={{textDecoration: lableMap[key].exist ? 'none' : 'line-through'}} title={lableMap[key].tags || ''}>
        {lableMap[key].name}
          <span className="tag algo">{lableMap[key].algorithm}</span>
      </span>
      );
    } else {
      let rank = (/\brank_[^,]+/ig.exec(lableMap[key].tags || '') || [])[0];
      return `${lableMap[key].algorithm|| ''} ${rank || ''}`;
    }
  }
  if (key === 'cxense') {
    return 'Cxense'
  } else if (dimension === 'algorithm') {
    if (key === 'rnd') {
      return 'Fallback';
      // return 'Random';
    }
    let algorithmMeta = algorithms.find(d => d.key === key) || {};
    return algorithmMeta.shortLabel || algorithmMeta.label || key;
  } else if (dimension === 'site') {
    let siteMeta = sites.find(d => d.key === key);
    return siteMeta ? siteMeta.label : key;
  } else if (dimension === 'slot_position') {
    return isNaN(key) ? '-' : (Number(key) + 1);
  } else if (dimension === 'widget_type') {
    return widgetTypeMapping[key] || key;
  } else {
    return key;
  }
}
export default {dashboardCards, timeseriesDimensions, getDimensionKeyLabel, getMetricLabel, getDimensionLabel}
